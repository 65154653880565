<template>
  <Panel title="Leads" v-bind:is-collapsible="false">
    <span slot="toolbar-buttons">
      <div v-if="breakingLeads">
        <i class="fa fa-spinner fa-spin tw-mr-2" />
        <span class="font-bold">Leads worden gebreakt ... </span>
      </div>
      <button
        v-else
        type="button"
        aria-expanded="false"
        data-toggle="dropdown"
        class="btn btn-default dropdown-toggle btn-xs"
      >
        Acties <span class="caret" />
      </button>
      <ul class="dropdown-menu pull-right">
        <li>
          <!-- Not passing any parameter as we don't want to change activity type -->
          <button type="button" class="dropdown-action" @click="showQuickLeadModal()">
            <i class="fa fa-plus" /> Snelle lead
          </button>
        </li>
        <template v-if="selectedLeads.length">
          <!-- Only for property/project -->
          <li v-if="!contact">
            <button type="button" class="dropdown-action" @click="showAssignTagModel">
              <i class="fas fa-tags" /> Tags toewijzen aan geselecteerde leads
            </button>
          </li>
          <li>
            <button type="button" class="dropdown-action" @click="showBreakModal">
              <i class="fa fa-trash" /> Geselecteerde leads breaken
            </button>
          </li>
          <li v-if="collaborator && collaborator.hasPerm('EXPORT_CONTACT_GROUP')">
            <button type="button" class="dropdown-action" @click="exportSelectedLeads">
              <i class="glyphicon glyphicon-export" /> Geselecteerde leads exporteren
            </button>
          </li>
          <li v-if="property && property.status === 3">
            <button type="button" class="dropdown-action" @click="showOwnerPresentationModal">
              <i class="glyphicon glyphicon-export" /> Geselecteerde kandidaten voorstellen aan eigenaar
            </button>
          </li>
        </template>
        <li v-if="selectedLeads.length && !contact">
          <button type="button" class="dropdown-action" @click="showMailClient">
            <i class="fa fa-send" /> Kandidaten mailen
          </button>
        </li>
        <li v-if="selectedLeads.length && property">
          <button type="button" class="dropdown-action" @click="showSmsBulkModal">
            <i class="fa fa-sms" /> Kandidaten sms'en
          </button>
        </li>
      </ul>
    </span>

    <DataTable
      v-model="selectedLeads"
      :loading="loading"
      :headers="headers"
      :can-select="true"
      :can-search="true"
      :infinite-scroll="true"
      :search-placeholder="searchPlaceholder"
      :min-search-chars="3"
      :all-record-ids="allLeadIds"
      :max-select-all-amount="MAX_SELECT_ALL_AMOUNT"
      v-bind="leads"
      class="tw--m-5 tw-shadow-none"
      @filter="queryLeads"
      @changedOrder="filterLeads"
      @infiniteLoad="infiniteLoad"
    >
      <template #filters>
        <div class="tw-flex tw-gap-1 tw-flex-wrap tw-items-end tw-select-none">
          <label v-for="(status, key) in statusTypes" :key="key" :for="`status_${key}`" :title="status">
            <input
              v-model="selectedStatuses"
              type="checkbox"
              :id="`status_${key}`"
              :name="`status_${key}`"
              :value="key"
              class="tw-peer tw-sr-only"
            />
            <span class="status-types">{{ status }}</span>
          </label>
        </div>
        <div class="tw-flex tw-gap-1 tw-flex-wrap tw-items-end tw-select-none">
          <label v-for="(stage, key) in stageTypes" :key="key" :for="`stage_${key}`" :title="stage">
            <input
              v-model="selectedStages"
              type="checkbox"
              :id="`stage_${key}`"
              :name="`stage_${key}`"
              :value="key"
              class="tw-peer tw-sr-only"
            />
            <span class="status-types">{{ stage }}</span>
          </label>
        </div>
      </template>

      <template #item-id="{ item }">
        <router-link :to="{ name: 'LeadDetails',  params: { id: item.id }}">
          <span v-if="contact">{{ item.entity_data.reference }}</span>
          <!-- It is possible the API returns a lead without a linked candidate -->
          <span v-else>{{ item.candidate ? item.candidate.name : '-' }}</span>
        </router-link>
      </template>

      <template #item-language="{ item }">
        <span class="tw-uppercase">
          {{ item.language }}
        </span>
      </template>

      <template #item-last_activity_name="{ item }">
        <div v-if="item.last_activity_name">
          {{ item.last_activity_name }}
        </div>
      </template>

      <template #item-last_activity_date="{ item }">
        <div v-if="item.last_activity_date">
          {{ item.last_activity_date | date-day }}
        </div>
      </template>

      <template #item-next_activity_name="{ item }">
        <span v-if="item.next_activity_name">
          {{ item.next_activity_name }}
        </span>
        <span v-else class="label label-danger">
          Geen activiteit
        </span>
      </template>
      <template #item-next_activity_date="{ item }">
        <div v-if="item.next_activity_date">
          {{ item.next_activity_date | date-day }}
        </div>
      </template>
      <template #item-contact_score="{ item }">
        <ContactScore
          v-if="item.contact_score && item.candidate"
          :score="item.contact_score"
          :contact-id="item.candidate.id"
        />
        <span v-else>-</span>
      </template>
      <template #item-hmodh_email_last_opened_on="{ item }">
        <span v-if="item.hmodh_email_last_opened_on">
          {{ item.hmodh_email_last_opened_on | date-day }}
        </span>
        <span v-else>
          -
        </span>
      </template>
      <template #item-hmodh_property_last_clicked_on="{ item }">
        <span v-if="item.hmodh_property_last_clicked_on">
          {{ item.hmodh_property_last_clicked_on | date-day }}
        </span>
        <span v-else>
          -
        </span>
      </template>
    </DataTable>

    <QuickLeadModal
      ref="leadModal"
      :contact="contact"
      :project="project"
      :property="property"
      @lead-saved="filterLeads()"
    />

    <ActivityFeedbackModal
      ref="breakModal"
      title="Geselecteerde leads breaken"
      :lead="null"
      :multi-mode="true"
      :entity="property || project"
      :show-break-button="true"
      :show-details-button="false"
      :show-finish-button="false"
      :handle-success-modal="false"
      @finished="breakSelectedLeads"
    />

    <ToggleButtonModal
      ref="contactGroupsModal"
      type="contacts"
      title="Groepen"
      :clear-on-close="true"
      @save="addLeadsToGroup"
    />

    <OwnerPresentationModal
      ref="ownerPresentationModal"
      :lead-ids="selectedLeads"
    />
    <SMSBulkModal
      ref="smsBulkModal"
      :lead-ids="selectedLeads"
    />
    <AssignTagModal
      ref="assignTagModal"
      :contact-ids="contactIds"
    />
  </Panel>
</template>

<script>
import Panel from '@/components/iam/Panel'
import DataTable from '@/components/DataTable'
import QuickLeadModal from '@/components/contacts/QuickLeadModal'
import ActivityFeedbackModal from '@/components/sales/ActivityFeedbackModal'
import SMSBulkModal from '@/components/sales/SMSBulkModal.vue'
import ToggleButtonModal from '@/components/iam/ToggleButtonModal'
import OwnerPresentationModal from '@/components/properties/OwnerPresentationModal'
import EventBus from '@/components/iam/bus'
import ContactScore from '@/components/contacts/ContactScore.vue'
import AssignTagModal from '@/components/contacts/AssignTagModal'

import { mapState, mapActions, mapGetters } from 'vuex'
import { startLoadingModal, errorModal, successModal, stopLoadingModal } from '@/modalMessages'
import { getLeads, getLeadIds, breakLeads, exportLeadContacts } from '@/services/sales'
import { addContactsToGroup, getContactsByIds } from '@/services/contacts'
import { createLog, getJobStatus } from '@/services/apiService'
import { propertyIsSales, propertyIsRental } from '@/utils/properties'
import { poll } from '@/utils/helpers'

export default {
  name: 'LeadPanel',
  components: {
    Panel,
    DataTable,
    QuickLeadModal,
    ActivityFeedbackModal,
    ToggleButtonModal,
    OwnerPresentationModal,
    SMSBulkModal,
    ContactScore,
    AssignTagModal
  },
  data () {
    return {
      loading: false,
      selectedStatuses: [0], // 0 indicates that "Short List" is the default
      selectedStages: [],
      // The object leads includes keys to all the props that are needed in the DataTable, hence, we can use v-bind directly for clean code
      leads: {
        count: null,
        next: null,
        previous: null,
        results: []
      },
      allLeadIds: [],
      selectedLeads: [],
      breakingLeads: false,
      contactIds: []
    }
  },
  computed: {
    ...mapState('contacts', { getContact: 'contact' }),
    ...mapGetters('sales', ['getLeadsForProperty', 'getLeadsForProject']),
    ...mapGetters('properties', ['getPropertyById', 'getProjectById']),
    ...mapGetters('contacts', ['formattedGroups']),
    ...mapGetters('account', ['collaborator']),

    contact () {
      // We don't want the previously fetched contact state leaking over to the other views
      return this.$route.meta.isContactRoute ? this.getContact : null
    },
    property () {
      return this.$route.meta.entity_type === 'property' ? this.getPropertyById(this.$route.params.id) : null
    },
    project () {
      return this.$route.meta.entity_type === 'project' ? this.getProjectById(this.$route.params.id) : null
    },
    headers () {
      return [
        { value: 'id', text: 'Lead' },
        ...this.contact ? [] : [{ value: 'language', text: 'Taal', orderable: true }],
        { value: 'funnel_name', text: 'Funnel', orderable: true },
        { value: 'status_display_name', text: 'Status', orderable: true },
        ...this.contact ? [{ value: 'entity_data.address', text: 'Adres', orderable: false }] : [],
        { value: 'last_activity_name', text: 'Laatste activiteit', orderable: true },
        { value: 'last_activity_date', text: 'Datum laatste activiteit', orderable: true },
        { value: 'next_activity_name', text: 'Volgende activiteit', orderable: true },
        { value: 'next_activity_date', text: 'Datum volgende activiteit', orderable: true },
        ...this.contact ? [] : [{ value: 'contact_score', text: 'Contactscore', orderable: true }],
        ...this.contact ? [] : [{ value: 'hmodh_email_last_opened_on', text: 'HMODH-e-mail gelezen', orderable: true }],
        ...this.contact ? [] : [{ value: 'hmodh_property_last_clicked_on', text: 'HMODH-pand bekeken', orderable: true }]
      ]
    },
    searchPlaceholder () {
      return this.contact ? 'Zoeken op adres (min. 3 karakters)' : 'Zoeken op contact (min. 3 karakters)'
    },
    statusTypes () {
      return {
        0: 'Short List',
        1: 'Long List',
        2: 'Breaks',
        3: 'Deals',
        4: 'Cold List'
      }
    },
    stageTypes () {
      if (!this.property) return {}
      if (propertyIsSales(this.property)) {
        return {
          4: 'Contact',
          5: 'Bezoek',
          6: 'Onderhandeling'
        }
      }
      if (propertyIsRental(this.property)) {
        return {
          7: 'Contact',
          8: 'Bezoekaanvraag',
          9: 'Kandidatuur',
          10: 'Voorgesteld'
        }
      }
      return {}
    },
    source () {
      if (this.property) return 'property'
      if (this.project) return 'project'
      return 'contact'
    }
  },
  constants: {
    MAX_SELECT_ALL_AMOUNT: 2000
  },
  watch: {
    '$route.query': {
      immediate: true,
      handler (query) {
        if (query.status?.length) this.selectedStatuses = query.status
        if (query.stage?.length) this.selectedStages = query.stage
      }
    },
    selectedStatuses: {
      immediate: true,
      handler () {
        return this.filterLeads()
      }
    },
    selectedStages: {
      immediate: true,
      handler () {
        return this.filterLeads()
      }
    }
  },
  mounted () {
    // Putting this in mounted to wait for the DOM to get loaded and the $refs to be available.
    const activityType = this.$route.params?.quickLeadActivityType
    if (activityType) this.showQuickLeadModal(activityType)
  },
  methods: {
    ...mapActions('contacts', ['pollContactExportStatus']),

    showBreakModal () {
      this.$refs.breakModal.show()
    },
    showQuickLeadModal (defaultActivity) {
      this.$refs.leadModal.show(defaultActivity)
    },
    showGroupModal () {
      this.$refs.contactGroupsModal.show()
    },
    showOwnerPresentationModal () {
      this.$refs.ownerPresentationModal.show()
    },
    async showAssignTagModel () {
      const response = await getContactsByIds({ match_ids: this.selectedLeads })
      this.contactIds = response.data.map(obj => obj.id)
      this.$refs.assignTagModal.show()
      return response
    },
    async showSmsBulkModal () {
      this.$refs.smsBulkModal.show()
    },
    async queryLeads (data = {}) {
      const payload = {
        action_id: 27, // Lead query action id
        meta: { value: data.params.query, source: this.source }
      }
      await createLog(payload)
      await this.filterLeads(data)
    },
    async filterLeads (data = {}) {
      const params = { ...data.params, source: this.source }
      if (this.contact) params.candidate = this.contact.id
      else if (this.property) params.property = this.property.id
      else if (this.project) params.project = this.project.id
      if (this.selectedStatuses.length) params.status = this.selectedStatuses.join()
      if (this.selectedStages.length) params.stage = this.selectedStages.join()

      const [leads] = await Promise.all([
        this.fetchLeads({ params }),
        this.fetchLeadIds({ params })
      ])
      // We assign the leads here because we don't want to override when new page is fetched, but concatenate.
      this.leads = leads
      return leads
    },
    async fetchLeads (payload) {
      try {
        this.loading = true
        const response = await getLeads(payload)
        return response.data
      } catch (error) {
        errorModal('Fout bij het laden van leads, probeer het opnieuw.')
      } finally {
        this.loading = false
      }
    },
    async fetchLeadIds (payload) {
      try {
        const response = await getLeadIds(payload)
        this.allLeadIds = response.data
        localStorage.setItem('lead_ids', response.data)
        return response.data
      } catch (error) {
        errorModal('Er is iets misgegaan, selecteer alle functionaliteit werkt mogelijk niet. Probeer het opnieuw.')
      }
    },
    async infiniteLoad ($infinite, next) {
      try {
        if (!next) {
          // No more data and state is loaded
          if (this.leads.results.length) $infinite.loaded()
          return $infinite.complete()
        }
        const leads = await this.fetchLeads({ url: next })
        const results = [...this.leads.results, ...leads.results]
        this.leads = { ...leads, results }
        $infinite.loaded()
        return leads
      } catch (error) {
        $infinite.error()
        console.error(error)
      }
    },
    async breakSelectedLeads () {
      try {
        this.breakingLeads = true
        const feedback_internal = this.$refs.breakModal.feedbackInternal || ' '
        const feedback_reporting = this.$refs.breakModal.feedbackReporting || ' '
        const leads = [...this.selectedLeads]
        const response = await breakLeads({ leads, feedback_internal, feedback_reporting })
        await poll(response?.data?.job_id, getJobStatus, 1000)
        successModal('De geselecteerde leads werden gebreakt.')
        return await this.filterLeads()
      } catch (error) {
        console.error(error)
        errorModal('Er ging iets mis bij het breaken van de leads, gelieve opnieuw te proberen', true)
      } finally {
        this.breakingLeads = false
      }
    },

    async exportSelectedLeads () {
      try {
        startLoadingModal('Bezig met exporteren van leads.')
        const payload = { lead_ids: [...this.selectedLeads] }
        const response = await exportLeadContacts(payload)
        const jobId = response.data.job_id
        await this.pollContactExportStatus(jobId)
        successModal('De leads zijn geëxporteerd.')
      } catch (e) {
        console.error(e)
        errorModal('Er ging iets mis tijdens het exporteren van de leads.')
      }
    },
    async addLeadsToGroup (values) {
      try {
        startLoadingModal('Wijzigingen worden doorgevoerd.')
        this.$refs.contactGroupsModal.clear()

        const payload = {
          match_id_list: [...this.selectedLeads],
          group_id_list: [...values]
        }
        const response = await addContactsToGroup(payload)
        successModal('De contacten zijn successvol toegevoegd aan de groep')
        return response
      } catch (e) {
        errorModal('Kan contacten niet toevoegen aan de groep, gelieve opnieuw te proberen')
      }
    },
    async showMailClient () {
      try {
        startLoadingModal('Kandidatengegevens inladen ...')
        const response = await getContactsByIds({ match_ids: this.selectedLeads })
        const contacts = response?.data.map(contact => ({ type: 'candidate', contact: contact }))
        const context = this.property ? { properties: [this.property] } : { project: this.project }
        stopLoadingModal()
        EventBus.$emit('mailclient-prefill-and-show', {
          contacts,
          context
        })
      } catch (error) {
        console.error(error)
        errorModal('Er ging iets mis bij het laden van de kandidaten.')
      }
    }
  }
}
</script>

<style scoped>
.dropdown-action {
  @apply tw-mx-3.5 tw-my-2 hover:tw-opacity-80;
}

.status-types {
  @apply tw-px-1.5 tw-py-0.5 tw-border tw-font-normal tw-rounded tw-cursor-pointer;
  @apply peer-checked:tw-border-info peer-checked:tw-bg-info peer-checked:tw-text-white;
}
</style>
